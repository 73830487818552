html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  font-family: url("./fonts/InriaSerif-Regular.ttf");
}

/*  */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Imported Background */
@keyframes ring {
  0% {
    background-position:
      50% 50%,
      calc(50% + 3em) calc(50% - .5em),
      calc(50% + 4em) calc(50% - 1.5em),
      calc(50% + 3em) calc(50% - 2.5em),
      50% calc(50% - 3em),
      calc(50% - 3em) calc(50% - 2.5em),
      calc(50% - 4em) calc(50% - 1.5em),
      calc(50% - 3em) calc(50% - .5em),

      0 0;
    background-size:
      3em 3em,
      2.5em 3em,
      1em 3em,
      2.5em 3em,
      3em 3em,
      2.5em 3em,
      1em 3em,
      2.5em 3em,

      100% 100%;
  }

  100% {
    background-position:
      calc(50% - 3em) calc(50% - .5em),
      50% 50%,
      calc(50% + 3em) calc(50% - .5em),
      calc(50% + 4em) calc(50% - 1.5em),
      calc(50% + 3em) calc(50% - 2.5em),
      50% calc(50% - 3em),
      calc(50% - 3em) calc(50% - 2.5em),
      calc(50% - 4em) calc(50% - 1.5em),

      0 0;
    background-size:
      2.5em 3em,
      3em 3em,
      2.5em 3em,
      1em 3em,
      2.5em 3em,
      3em 3em,
      2.5em 3em,
      1em 3em,

      100% 100%;
  }
}
body {
  height: 100%;
  font-size: 1em;
  background-color: #222;
  background-image:
    radial-gradient(hsla(0, 0%, 100%, .6) 40%,
      hsla(0, 0%, 100%, 0) 60%),
    radial-gradient(hsla(0, 0%, 100%, .6) 40%,
      hsla(0, 0%, 100%, 0) 60%),
    radial-gradient(hsla(0, 0%, 100%, .6) 40%,
      hsla(0, 0%, 100%, 0) 60%),
    radial-gradient(white 50%,
      hsla(0, 0%, 100%, 0) 53%),
    radial-gradient(white 50%,
      hsla(0, 0%, 100%, 0) 53%),
    radial-gradient(white 50%,
      hsla(0, 0%, 100%, 0) 53%),
    radial-gradient(white 50%,
      hsla(0, 0%, 100%, 0) 53%),
    radial-gradient(hsla(0, 0%, 100%, .6) 40%,
      hsla(0, 0%, 100%, 0) 60%),

    radial-gradient(hsl(200, 80%, 60%),
      hsl(60, 0%, 20%));
  background-position:
    50% 50%,
    calc(50% + 3em) calc(50% - .5em),
    calc(50% + 4em) calc(50% - 1.5em),
    calc(50% + 3em) calc(50% - 2.5em),
    50% calc(50% - 3em),
    calc(50% - 3em) calc(50% - 2.5em),
    calc(50% - 4em) calc(50% - 1.5em),
    calc(50% - 3em) calc(50% - .5em),

    0 0;
  background-size:
    3em 3em,
    2.5em 3em,
    1em 3em,
    2.5em 3em,
    3em 3em,
    2.5em 3em,
    1em 3em,
    2.5em 3em,

    100% 100%;
  background-repeat: no-repeat;
  animation: .3s ring linear infinite reverse;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/*  */



img {
  height: 300px;
  width: 350px;
  border-radius: 100%;
}

#meetPhoto {
  width: 480px;
  height: 100%;
  border-radius: 0;
}

#iconHolder {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.icons{
  width: 40px;
  height: 40px;
  border-radius: 100% !important;
}

.reactIcons {
  position: relative;
  /* top: 5px; */
}

#homepage_name{
  margin:10px;
}

.footerContainer{
  margin-top: 5px;
  margin-bottom: 5px;
}

.App {
  text-align: center;
  vertical-align: middle;
}

.footerItem,
.headerItem {
  padding-right: 15px;
  text-decoration: none;
}
.headerItem{
  color: white;
}

#puzzledFace {
  width: 250px;
  height: 250px;
}

.displayPage {
  display: "block";
  width: 100%;
  height: 90vh;
  min-height: 80vmin;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

#cardContainer {
  display: flex;
  justify-content: space-around;
  position:relative;
  top: 465px;
}


.swapSpace {
  display: flex;
  justify-content: center;
  position: relative;
  
}
@media screen and (max-width: 360px) {
  .swapSpace{
    width: 100vw;
  } 
  
  #cardContainer {
    display: flex;
    justify-content: space-around;
    position:relative;
    top: 280px;
  }
  #homepage_image {
    width: 215px;
    height: 200px;
    border-radius: 10px;
  }
}

#moreWorkInfo {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 600px){
  .moreWorkInfo{
    display: none;
  }

  #aboutMeText{
    font-size: 13px;
  }

  #homepage_image{
    border-radius: 10px;
  }

  #iconHolder {
    display: flex;
    justify-content: space-around;
  }

}
@media screen and (max-width: 400px){
  .skillProjectImage {
    width:200px;
    height:200px;
    border-radius: 10px;
  }
}

.skillProjectImage {
  border-radius: 15px;
}

.skillCard{
  background: transparent;
  margin: 10px;
}

#softwareDiv,
#webDev,
#machineLearn,
#dataVisual,
#compVision,
#linuxSystem,
.skillCard,
.skills_outer_div {
  outline: 0 !important;
  border: 0;
}
.swapSpace::-webkit-scrollbar {
  display: none;
}
.swapSpace::-webkit-scrollbar-track {
  background: transparent;
}

.displayPage::-webkit-scrollbar {
  display: none;
  background-color: rgba(227, 224, 255, 0.4);
  border-radius: 25px;
}
.displayPage::-webkit-scrollbar-track {
  display: none;
  border-radius: 25px;
}
.displayPage::-webkit-scrollbar-thumb {
  display: none;
  background-color: lightpink;
  border-radius: 25px;
}
.displayPage::-webkit-scrollbar-button {
  display: none;
  border-radius: 25px;
}

#notInterested,
#interested,
#superInterested {
  background-color: rgb(230, 216, 216);
  position: sticky;
  outline: 0;
  width: 80px;
  height: 35px;
  border-radius: 35px;
}

/*
  Education page animation.
*/
.eduSubcontainer{
  background-color: transparent;
  border: 0;
}

.educationDetails{
  background-color: rgba(51, 51, 51, 0.9);
  border-radius: 15px;
}

.educationDetails{
  color: white;
  padding: 10px;
}

.eduContainer {
  margin: 20px;
  border: 0;
}

#animation div {
  color:#fff;
  padding:5px 12px;
  height:40px;
  width:400px;
/*   display:block; */
  margin: 0 auto 40px auto;
}

#animation div:first-child {
  background:linear-gradient(to right, #10e8c7,#3EB7AE 60%, #10e8c7 );
  animation: show 5s linear infinite;
}

#animation div:nth-child(2) {
  background:radial-gradient(#c339f9,#99B4DE );
}

#animation div:last-child {
  background:linear-gradient(to bottom right, #ff3d3d, #ffd632);
}

#animation {
  height:50px;
  overflow:hidden;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  33% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  71% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}


.external_link{
  color: gray;
  text-decoration: none;
}

.linkedin_connect{
  margin: 5px;
}

.statusContainer{
  display: flex;
  justify-content: right;
}

.workRole{
  background-color: transparent;
  margin: 10px;
  border: 0;
  border-radius: 15px;
  box-shadow: 0px 35px 15px 15px rgba(51, 51, 51, 0.26);
}

#aboutMeText{
  align-items:center;
  text-align: center;
  border-radius: 10px;
  background-color:rgba(51, 51, 51, 0.9);
}

.footerList{
  position: -webkit-sticky;
  display: flex;
  list-style: none;
  justify-content: center;
}

#aboutMeText p{
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin: 5px;
  font-size: 1.3em;
  color: white;
  width:fit-content;
  width: -moz-fit-content;
}

@keyframes slideOut{
  0% {
    left: -40%;
    z-index: 1;
  }
  /* 25% {
    top: -50%;
  }
  50% {
    top: -30%;
  } */
  100% {
    left: 35%;
    z-index: inherit;
  }
}

.workStatus, .educationDetails{
  text-align:right;
  align-items: right;
}

.workDutyListVisible{
  width: fit-content;
  width: -moz-fit-content;
  align-items: left;
  text-align: left;
  /* list-style:inside; */
  display: block;
}

.dutyContainer{
  width:fit-content;
  width: -moz-fit-content;
  background-color: rgba(51, 51, 51, 0.9);
  border-radius: 10px;
  color: white;
}
.dutyContainer ul{
  background: transparent;
  list-style: circle;
  margin: 5px;
}

.institution_info{
  background:transparent;
}

.skillItemsContainer{
  background-color:rgba(51, 51, 51, 0.9);
  color:#fff;
  border-radius: 10px;
}

.meetDiv {
  display: flex;
  justify-content: left;
  background-image: url("./Data/Images/billyMicah.JPG");
  background-size: contain;
}

#meetSend, #meetClear {
  font-size: 1.0em;
  font-family: url("./fonts/InriaSerif-Light.ttf");
  height: 30px;
  width: 55px;
  border-radius: 10px;
}

.meetForm {
  font-size: 1.1em;
  font-family: url("./fonts/InriaSerif-Light.ttf");
}

.meetCalendly {
  font-size: 1.1em;
  font-family: url("./fonts/InriaSerif-Light.ttf");
  text-decoration: none;
  color: black;
}

.meetOption {
  font-size: 1.0em;
  font-family: url("./fonts/InriaSerif-Light.ttf");
  
  /* border-radius: 10px; */
}

.errorMessage {
  color: red;
  font-size: 0.8em;
  padding: 0;
  margin: 0;
}