* {
  font-family: url("./fonts/InriaSerif-Regular.ttf");

}

body, html, div, span, article, form,fieldset,
aside, h1, h2, h3, h4, h5, p, button {
  outline: 0;
  padding: 0;
  margin: 0;
  font-family: url("./fonts/InriaSerif-Regular.ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
